import {
    Card,
    CardActions,
    CardContent,
    Grid,
    makeStyles,
    Typography,
  } from "@material-ui/core";
  import React from "react";
  import autiusRojo from "../../assets/img/autius-rojo.svg";

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      backgroundColor: "rgba(159,46,37)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      padding: "10px 30px 30px 30px",
      borderRadius: "15px",
      maxWidth: "400px",
    },
  }));
  
  const Maintenance = () => {
    const classes = useStyles();
  
  
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={3}>
               
                <Grid item xs={12}>
                  <div
                    style={{
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "200px" }}
                      src={autiusRojo}
                      alt="React Logo"
                    />{" "}
                  </div>
                </Grid>
  
               
                <Typography>Lo sentimos. Estamos cerrados por mantenimiento mientras realizamos algunos cambios importantes para poder ofrecerte una mejor experiencia</Typography>
                <Typography>Gracias.</Typography>
              </Grid>
            </CardContent>
            <CardActions>
             
            </CardActions>
        </Card>
      </div>
    );
  };
  
  export default Maintenance;
  